.topbanner {
	border: 0;
	margin: 0;
	background-color: $primary;
	h3 {
		font-weight: 600;
	}
}

.lastoria {
	padding: 4em 0;
}
.servizi {
	padding: 2em 0em 6em 0em;
	h3 {
		font-size: 20px;
	}
	.service-box {
		img {
			max-width: 110px;
			padding-bottom: 20px;
		}
	}
}
.portfolio-box-caption {
	i {
		font-size: 40px;
	}
}